import { render, staticRenderFns } from "./saleToday.vue?vue&type=template&id=478ef272&scoped=true&"
import script from "./saleToday.vue?vue&type=script&lang=js&"
export * from "./saleToday.vue?vue&type=script&lang=js&"
import style0 from "./saleToday.vue?vue&type=style&index=0&id=478ef272&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478ef272",
  null
  
)

export default component.exports